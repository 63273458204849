var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"template-airport@undefined"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { isProductionBuild } from './src/utils/isProductionBuild';

if (isProductionBuild()) {
	Sentry.init({
		dsn: 'https://8d8735b4b626338a93914329143bd77d@o232156.ingest.us.sentry.io/4507503141388288',
		tracesSampleRate: 0.1,
		debug: false,
		environment: process.env.ENVIRONMENT,
	});
}
